import { render, staticRenderFns } from "./App.vue?vue&type=template&id=b8a24ebe&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib'
import { VApp } from 'vuetify/lib'
import { VBadge } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VDatePicker } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAvatar } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileSubTitle } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VNavigationDrawer } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VSnackbar } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarSideIcon } from 'vuetify/lib'
import { VToolbarTitle } from 'vuetify/lib'
installComponents(component, {VAlert,VApp,VBadge,VBtn,VCard,VCardActions,VCardText,VCardTitle,VChip,VDatePicker,VDialog,VDivider,VIcon,VList,VListTile,VListTileAvatar,VListTileContent,VListTileSubTitle,VListTileTitle,VNavigationDrawer,VSelect,VSnackbar,VSpacer,VTextField,VTextarea,VToolbar,VToolbarSideIcon,VToolbarTitle})
