/* eslint-disable */
export default {
  changeMassive: ({ commit }, payload) => {
    commit('changeMassive', payload)
  },
  login: ({ commit }, creds) => {
    commit('login')
  },
  saveUser: ({ commit }, payload) => {
    commit('saveUser', payload)
  },
  changeSnack: ({ commit }, payload) => {
    commit('changeSnack', payload)
  },
  changeIa: ({ commit }, payload) => {
    commit('changeIa', payload)
  },
  updateTitle: ({ commit }, payload) => {
    commit('updateTitle', payload)
  },
  updatePayments: ({ commit }, payload) => {
    commit('updatePayments', payload)
  },
  updateStates: ({ commit }, payload) => {
    commit('updateStates', payload)
  },
  updateComments: ({ commit }, payload) => {
    commit('updateComments', payload)
  },
  saveKey({ commit }, payload) {
    commit('saveKey', payload)
  },
  setNotifications({ commit }, payload) {
    commit('setNotifications', payload)
  },
  setNotificationsInfo({ commit }, payload) {
    commit('setNotificationsInfo', payload)
  },
  logout: ({ commit }) => {
    commit('logout')
  },
  setUserActives: ({ commit }, payload) => {
    commit('setUserActives', payload)
  },
  changeSearch: ({ commit }, payload) => {
    commit('changeSearch', payload)
  },
  setTextSearch: ({ commit }, payload) => {
    commit('setTextSearch', payload)
  },
  setConfirm: ({ commit }, payload) => {
    commit('setConfirm', payload)
  },
  setLoading: ({ commit }, payload) => {
    commit('setLoading', payload)
  },
  changeDark: ({ commit }, payload) => {
    commit('changeDark', payload)
  },
  setCars: ({ commit }, payload) => {
    commit('setCars', payload)
  },
  setCommunes: ({ commit }, payload) => {
    commit('setCommunes', payload)
  },
  setSchedules: ({ commit }, payload) => {
    commit('setSchedules', payload)
  },
  setHomeDetail: ({ commit }, payload) => {
    commit('setHomeDetail', payload)
  },
  setMini: ({ commit }, payload) => {
    commit('setMini', payload)
  },
  setNewOrder: ({ commit }, payload) => {
    commit('setNewOrder', payload)
  },
  setSettings: ({ commit }, payload) => {
    commit('setSettings', payload)
  },
  updateDisenadores: ({ commit }, payload) => {
    commit('updateDisenadores', payload)
  },
  setGeneral: ({ commit }, payload) => {
    commit('setGeneral', payload)
  },
  setRememberme: ({ commit }, payload) => {
    commit('setRememberme', payload)
  },
  setColorPrimary: ({ commit }, payload) => {
    commit('setColorPrimary', payload)
  },
  setGoogle: ({ commit }, payload) => {
    commit('setGoogle', payload)
  },
  setColorFlowers: ({ commit }, payload) => {
    commit('setColorFlowers', payload)
  }
}
