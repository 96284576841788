import { render, staticRenderFns } from "./navMenu.vue?vue&type=template&id=d5cb9ed2&"
import script from "./navMenu.vue?vue&type=script&lang=js&"
export * from "./navMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListGroup } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAction } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VSubheader } from 'vuetify/lib'
installComponents(component, {VDivider,VFlex,VIcon,VLayout,VList,VListGroup,VListTile,VListTileAction,VListTileContent,VListTileTitle,VSubheader})
