import Vue from 'vue'
import Router from 'vue-router'
import _ from 'lodash'
import { auth } from '../fireStore'
import store from '../store'
import routes from './router'

Vue.use(Router)

const router = new Router(routes)

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  store.dispatch('changeSearch', {
    active: false,
    action: false,
    text: '',
    placeholder: ''
  })
  if (to.meta.title !== undefined) {
    store.dispatch('updateTitle', to.meta.title)
  }

  if (to.name === 'TellInvetory') {
    store.dispatch('changeSearch', {
      active: true,
      action: false,
      text: '',
      placeholder: 'Buscar por codigo, nombre...'
    })
  }
  auth.onAuthStateChanged(user => {
    const currentUser = user
    const storeUser = store.getters.getUser
    if (requiresAuth) {
      if (currentUser && (storeUser !== null && _.size(storeUser) > 0)) {
        if (storeUser.role.id === 7 && to.name === 'home') {
          next({ name: 'taller' })
          return
        }
        next()
      } else {
        next({ name: 'login' })
      }
    } else {
      next()
    }
  })
}

router.beforeEach(waitForStorageToBeReady)

export default router
