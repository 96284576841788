import Vue from 'vue'

Vue.filter('capitalize', val => {
  let value = val
  if (!value) return ''
  value = value.toString().toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('toHTML', val => {
  let value = val
  if (typeof value === 'undefined') {
    return
  }
  const formatingValues = [
    {
      from: '&aacute;',
      to: 'á'
    },
    {
      from: '&eacute;',
      to: 'é'
    },
    {
      from: '&iacute;',
      to: 'í'
    },
    {
      from: '&oacute;',
      to: 'ó'
    },
    {
      from: '&uacute;',
      to: 'ú'
    },
    {
      from: '&ntilde;',
      to: 'ñ'
    },
    {
      from: '&amp;iacute;',
      to: 'í'
    },
    {
      from: '&iexcl;',
      to: '¡'
    },
    {
      from: '&quot;',
      to: "'"
    },
    {
      from: '&reg;',
      to: '®'
    },
    {
      from: '&copy;',
      to: '©'
    },
    {
      from: '&trade;',
      to: '™'
    }
  ]
  for (let i = 0; i < formatingValues.length; i += 1) {
    value = value.split(formatingValues[i].from).join(formatingValues[i].to)
  }
  // eslint-disable-next-line consistent-return
  return value
})

Vue.filter('toPrice', val => {
  let number = val
  const negative = number < 0
  number = number > 0 ? number : number * -1

  let value = (number / 1).toFixed(0).replace('.', ',')
  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  number = negative ? `- $${value}` : `$${value}`

  return number
})

Vue.filter('toPriceOut', val => {
  let number = val
  const negative = number < 0
  number = number > 0 ? number : number * -1

  let value = (number / 1).toFixed(0).replace('.', ',')
  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  number = negative ? `- ${value}` : `${value}`

  return number
})
