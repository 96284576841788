import Vue from 'vue';
import linkify from 'vue-linkify';

Vue.directive('linkified', linkify);
Vue.directive('focus', (el) => {
  el.focus();
});
Vue.directive('price', (el, opt) => {
  let number = (opt.value === '') ? el.innerHTML : opt.value;
  const negative = (number > 0);
  number = (number > 0) ? number : number * -1;

  let val = (number / 1).toFixed(0).replace('.', ',');
  val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  number = (negative && number > 0) ? `- $${val}` : `$${val}`;
  // eslint-disable-next-line no-param-reassign
  el.innerHTML = number;
});
