import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import localForage from 'localforage'
import actions from './store/actions'
import getters from './store/getters'
import mutations from './store/mutations'
import state from './store/initialState'

const vuexPersist = new VuexPersist({
  key: 'app',
  storage: localForage,
  asyncStorage: true
})

Vue.use(Vuex)

const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  plugins: [vuexPersist.plugin]
})

export default store
