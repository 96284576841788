<template>
  <v-avatar :size="size" :color="color">
    <span :class="(textClass != '') ? `white--text ${textClass}` : `white--text`">{{ textFormat }}</span>
  </v-avatar>
</template>

<script>
export default {
  name: 'AvatarLetter',
  props: {
    text: String,
    color: String,
    textClass: String,
    size: {
      type: Number,
      default: 38,
    },
  },
  computed: {
    textFormat() {
      return this.text.charAt(0).toUpperCase();
    },
  },
};
</script>
