<template>
  <div class="loading-fixed" v-if="loading">
    <v-card class="pa-4">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'loadingCard',
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
};
</script>

<style>
.loading-fixed {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #00000030;
}
.loading-fixed .v-card{
  height: 99px;
  width: 99px;
}
</style>
