// eslint-disable-next-line
import '@babel/polyfill'
import Vue from 'vue'
import jQuery from 'jquery'
import App from './App.vue'
import router from './routes/index'
import store from './store'
import './config/use'
import './config/directive'
import './config/filter'
import './plugins/vuetify'
import './keyFunctions'

window.jQuery = jQuery
window.$ = jQuery

Vue.config.productionTip = false
Vue.axios.interceptors.response.use(
  res => {
    store.dispatch('setLoading', false)
    return res
  },
  error => {
    store.dispatch('setLoading', false)
    Promise.reject(error)
  }
)

Vue.axios.interceptors.request.use(
  res => {
    store.dispatch('setLoading', true)
    const config = res
    const user = store.getters.getUser
    if (user !== null) {
      config.headers.Authorization = user.token
    }
    return config
  },
  error => {
    store.dispatch('setLoading', true)
    Promise.reject(error)
  }
)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
