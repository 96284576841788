import store from './store'

window.addEventListener('keydown', e => {
  if (e.keyCode === 13) {
    store.dispatch('saveKey', 'Enter')
  } else if (e.keyCode === 27) {
    store.dispatch('saveKey', 'Escape')
  } else if (e.keyCode === 32) {
    store.dispatch('saveKey', 'Space')
  } else if (e.keyCode === 87 && e.shiftKey) {
    store.dispatch('saveKey', 'shiftW')
  } else if (e.keyCode === 13 && e.shiftKey) {
    store.dispatch('saveKey', 'shiftEnter')
  }
})
