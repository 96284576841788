/* eslint-disable */
export default {
  getDrawer: state => {
    return state.drawer
  },
  isLoggedIn: state => {
    if (state.user === {} || state.user === null) return false
    else return state.user.id !== undefined ? true : false
  },

  /* News */
  getMini: state => {
    return state.mini
  },
  getHomeDetail: state => {
    return state.homeDetail
  },
  getMassive: state => {
    return state.massive
  },
  getSnack: state => {
    return state.snack
  },
  getIa: state => {
    return state.ia
  },
  getTitle: state => {
    return state.title
  },
  getPayments: state => {
    return state.payments
  },
  getComments: state => {
    return state.comments
  },
  getStates: state => {
    return state.states
  },
  getKey: state => {
    return state.key
  },
  getNotifications: state => {
    return state.notifications
  },
  getNotificationsInfo: state => {
    return state.notificationsInfo
  },
  getUser: state => {
    return state.user === undefined ? {} : state.user
  },
  getUserActives: state => {
    return state.userActives
  },
  getSearch: state => {
    return state.search
  },
  getSearchText: state => {
    return state.search.text
  },
  getConfirm: state => {
    return state.confirm
  },
  getLoading: state => {
    return state.loading
  },
  getSettings: state => {
    return state.settings
  },
  getVersion: state => {
    return state.version
  },
  // Uso ****
  getTheme: state => {
    return state.dark ? state.themeDark : state.themeLight
  },
  getDark: state => {
    return state.dark
  },
  getMethodsPaymet: state => {
    return state.payments
  },
  getDealers: state => {
    return state.dealers
  },
  getCars: state => {
    return state.cars
  },
  getCommunes: state => {
    return state.communes
  },
  getSchedules: state => {
    return state.schedules
  },
  getStats: state => {
    return state.stats
  },
  getDisenadores: state => {
    return state.disenadores
  },
  getNewOrder: state => {
    return state.newOrder
  },
  getGeneral: state => {
    return state.general
  },
  getRememberme: state => {
    return state.rememberMe
  },
  getGoogle: state => {
    return state.google
  },
  getColorFlowers: state => {
    return state.colorFlowers
  }
}
