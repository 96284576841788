import moment from 'moment'
import colors from 'vuetify/es5/util/colors'
import { version } from '../../package.json'

export default {
  version,
  drawer: true,
  colorFlowers: null,
  google: null,
  isLoggedIn: false,
  dark: false,
  mini: false,
  loading: false,
  homeDetail: {
    grafico1: null,
    grafico2: null,
    grafico3: null,
    update: null
  },
  massive: {
    key: 0,
    send: 0
  },
  key: {
    name: '',
    active: false
  },
  snack: {
    active: false,
    text: 'Test'
  },
  ia: {
    active: false,
    text: ''
  },
  confirm: {
    active: false,
    title: 'Titulo',
    description: 'Bla, Bla, Blac',
    action: {}
  },
  search: {
    active: false,
    action: false,
    text: '',
    placeholder: ''
  },
  notifications: [],
  notificationsInfo: [],
  totalNotifications: 0,
  title: '',
  user: {},
  userActives: [],
  states: [],
  schedules: [],
  comments: [],
  payments: [],
  dealers: [],
  cars: [],
  disenadores: [],
  communes: [],
  themeLightTwo: {
    background: '#FFFFFF',
    primary: '#F44336',
    secondary: '#FF5252',
    default: '#000000',
    accent: '#FFA726',
    error: colors.red.accent3,
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    greyback: '#000000',
    back: '#666666'
  },
  themeLight: {
    background: '#FFFFFF',
    primary: '#ffac81',
    secondary: '#ed4b82',
    default: '#000000',
    accent: '#637bfe',
    error: colors.red.accent3,
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    greyback: '#000000',
    back: '#666666'
  },
  themeDark: {
    background: '#FFFFFF',
    primary: '#B71C1C',
    secondary: '#FF1744',
    default: '#000000',
    accent: '#E65100',
    error: colors.red.accent3,
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    greyback: '#ffffff',
    back: '#ffffff'
  },
  stats: {
    proceso: 0,
    despacho: 0,
    entregados: 0,
    total: 0,
    product_today: 0,
    product_tomorrow: 0,
    update: null
  },
  newOrder: {
    typeShipping: null,
    type: null,
    shipping: null,
    state: {},
    dateShipping: null,
    message: null,
    paymentMethod: {},
    schedule: {},
    products: [],
    store: {},
    address: {
      id: null,
      name: null,
      phone: null,
      street: null,
      number: null,
      latitude: null,
      longitude: null,
      type: null,
      typeHospital: null
    },
    user: {}
  },
  newOrderClean: {
    typeShipping: null,
    type: null,
    shipping: null,
    state: {},
    dateShipping: null,
    message: null,
    paymentMethod: {},
    schedule: {},
    products: [],
    store: {},
    address: {
      id: null,
      name: null,
      phone: null,
      street: null,
      number: null,
      latitude: null,
      longitude: null,
      type: null,
      typeHospital: null
    },
    user: {
      id: null,
      name: null,
      email: null,
      phone: null,
      addresses: [],
      status: true,
      alert: null
    }
  },
  settings: {
    printer: 'POS-80',
    soundNotification: 'to-the-point.mp3',
    soundNewWhatsApp: 'to-the-point.mp3',
    page: 'index'
  },
  general: {
    date: moment().format('YYYY-MM-DD'),
    status: 2
  },
  rememberMe: {}
}
