// eslint-disable-next-line import/no-duplicates
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/messaging'
import 'firebase/firestore'

const firebaseApp = firebase.initializeApp(
  {
    apiKey: 'AIzaSyCZ9FIryOkCqqt0XHV8DmythiQ7J0XbcIQ',
    authDomain: 'rosalindared-a32e6.firebaseapp.com',
    databaseURL: 'https://rosalindared-a32e6.firebaseio.com',
    projectId: 'rosalindared-a32e6',
    storageBucket: 'rosalindared-a32e6.appspot.com',
    messagingSenderId: '776733001873',
    appId: '1:776733001873:web:98052084599a0179fdd730'
  },
  'WhatsApp'
)

export const firestore = firebaseApp.firestore()
export const storage = firebaseApp.storage()
export const auth = firebaseApp.auth()
export const db = firebaseApp.database()
