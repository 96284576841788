<template>
  <v-menu
    v-model="notificationsMenu"
    :nudge-width="300"
    :max-width="300"
    fixed
    left
    offset-y
    :nudge-top="-12"
    :close-on-content-click="false"
  >
    <v-btn slot="activator" dark icon flat>
      <v-badge color="accent" left>
        <span v-if="total > 0" slot="badge" v-text="total"></span>
        <v-icon v-if="total > 0"> inbox </v-icon>
        <v-icon v-else> inbox </v-icon>
      </v-badge>
    </v-btn>
    <v-card>
      <v-list v-if="notifications.length > 0" class="pa-0" two-line color="accent">
        <template v-for="(item, index) in notifications">
          <v-list-tile
            :key="index"
            :class="`${item.readStatus === false ? 'no-read' : ''} ${setColor(item.link.type)}`"
            avatar
            @click="goChatMessenger(item)"
          >
            <v-list-tile-content>
              <v-list-tile-title
                class="text--primary"
                style="text-overflow: inherit !important; white-space: unset; height: auto"
              >
                {{ item.body }}
              </v-list-tile-title>
              <v-list-tile-sub-title> {{ item.user.name }} </v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-list-tile-action-text :class="setColorText(item.link.type)">
                {{ $moment.unix(item.time).fromNow() }}
              </v-list-tile-action-text>
              <v-icon color="darken-2"> reply </v-icon>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider v-if="index + 1 !== notifications.length" :key="`notifications-${index}`"> </v-divider>
        </template>
      </v-list>
      <div v-if="notifications.length === 0">
        <div class="pa-3 text-xs-center grey--text text--darken-3">No existe notificaciones</div>
      </div>
      <v-btn color="secondary" class="ma-0" block @click="$router.push({ name: 'historyNotifications' })">
        Ver todas las notificaciones
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script>
import { firestore } from '../../fireStore'

export default {
  name: 'NotificationMenuInfo',
  data() {
    return {
      notificationsMenu: false
    }
  },
  computed: {
    notifications() {
      let notifications = this.$store.getters.getNotificationsInfo
      if (notifications.length > 0) {
        notifications = this._.orderBy(notifications, ['time'], ['desc'])
        notifications = this._.chunk(notifications, 8)
        return notifications[0]
      }
      return notifications
    },
    total() {
      const notifications = this._.filter(this.notifications, ['readStatus', false])
      return notifications.length
    },
    user() {
      return this.$store.getters.getUser
    },
    settings() {
      return this.$store.getters.getSettings
    }
  },
  mounted() {},
  methods: {
    setColorText(type) {
      // eslint-disable-next-line no-param-reassign
      type = parseInt(type)
      switch (type) {
        case 1:
          return 'white--text'
        case 2:
          return 'white--text'
        case 3:
          return 'dark--text'
        default:
          return ''
      }
    },
    setColor(type) {
      // eslint-disable-next-line radix
      type = parseInt(type)
      switch (type) {
        case 1:
          return 'info white--text'
        case 2:
          return 'success white--text'
        case 3:
          return 'warning dark--text'
        default:
          return ''
      }
    },
    goChatMessenger(item) {
      const self = this
      firestore.collection('notifications').doc(String(self.user.storeID)).collection('store').doc(item.key).update({
        readStatus: true,
        readUser: self.user.name
      })
      let route = 'orderIndex'
      if (parseInt(item.link.type, 10) === 3) {
        route = 'orderProducts'
      }
      this.$router.push({ name: route, params: { id: item.link.info } })
      this.notificationsMenu = false
    }
  }
}
</script>

<style>
.v-list .no-read {
  background: #ff52521f;
}
</style>
