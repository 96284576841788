import Vue from 'vue'
import qs from 'qs'
import moment from 'moment'
import axios from 'axios'
import VueClipboard from 'vue-clipboard2'
import DrawerLayout from 'vue-drawer-layout'
import Meta from 'vue-meta'
import Sticky from 'vue-sticky-directive'
import * as VueGoogleMaps from 'vue2-google-maps'
import Quill from 'quill'
import VueQuillEditor from 'vue-quill-editor'
import { Vue2Storage } from 'vue2-storage'
import VueMq from 'vue-mq'
import VueAxios from 'vue-axios'
import VueMoment from 'vue-moment'
import VueLodash from 'vue-lodash'
import Croppa from 'vue-croppa'
import VueFusionCharts from 'vue-fusioncharts'
import FusionCharts from 'fusioncharts'
import Column2D from 'fusioncharts/fusioncharts.charts'
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel'
import '../../node_modules/moment/locale/es'
import '../../node_modules/slick-carousel/slick/slick.css'
import '../../node_modules/quill/dist/quill.core.css'
import '../../node_modules/quill/dist/quill.snow.css'
import '../../node_modules/quill/dist/quill.bubble.css'

const Font = Quill.import('formats/font')
const Link = Quill.import('formats/link')
Font.whitelist = [
  'raleway',
  'courier-new',
  'times-new-roman',
  'pacifico',
  'patrick-hand',
  'satisfy',
  'shadows-into-light'
]
Quill.register(Font, true)
class MyLink extends Link {
  static create(value) {
    const node = Link.create(value)
    value = Link.sanitize(value)
    node.setAttribute('href', value)
    node.removeAttribute('target')
    return node
  }

  format(name, value) {
    super.format(name, value)

    if (name !== this.statics.blotName || !value) {
      return
    }
    this.domNode.removeAttribute('target')
  }
}
Quill.register(MyLink)

Vue.use(Vue2Storage, {
  prefix: 'app_',
  driver: 'local',
  ttl: 60 * 60 * 24 * 1000
})
Vue.use(Sticky)
Vue.use(VueQuillEditor)
Vue.use(VueGoogleMaps, {
  load: {
    key:
      process.env.NODE_ENV === 'production'
        ? 'AIzaSyC296if0XDChF2voUJBDVR-doT-KPetyUc'
        : 'AIzaSyANtYASF2ZE3XT-REQftPqzXwdrM9mFHCA',
    libraries: 'places'
  }
})

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    xs: 600,
    sm: 960,
    md: 1264,
    lg: 1920,
    xl: Infinity
  }
})
Vue.use(Croppa)
Vue.use(VueMoment, {
  moment
})
Vue.use(VueAxios, axios)
Vue.use(VueLodash)
Vue.use(Meta, {
  keyName: 'metaInfo',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Default Title',
    // all titles will be injected into this template
    titleTemplate: '%s | My Awesome Webapp'
  }
})
Vue.use(VueClipboard)
Vue.use(DrawerLayout)
Vue.use(VueFusionCharts, FusionCharts, Column2D, GammelTheme)

Object.defineProperty(Vue.prototype, '$qs', { value: qs })
