import { version } from '../../package.json'
import initialState from './initialState'
/* eslint-disable */
export default {
  changeMassive(state, payload) {
    const appState = state
    appState.massive = payload
  },
  RESET_STATE(state) {
    if (version !== state.version) {
      for (let prop in state) {
        state[prop] = initialState[prop]
      }
    }
  },
  logout(state) {
    state.user = {}
  },
  saveUser(state, user) {
    state.user = user
  },
  changeSnack(state, payload) {
    state.snack.active = payload.active
    state.snack.text = payload.text
  },
  changeIa(state, payload) {
    state.ia.active = payload.active
    state.ia.text = payload.text
  },
  updateTitle(state, payload) {
    state.title = payload
  },
  updateStates(state, payload) {
    state.states = payload
  },
  updatePayments(state, payload) {
    state.payments = payload
  },
  updateComments(state, payload) {
    state.comments = payload
  },
  saveKey(state, payload) {
    const appState = state
    appState.key = {
      name: payload,
      active: !appState.key.active
    }
  },
  setNotifications(state, payload) {
    state.notifications = payload
  },
  setNotificationsInfo(state, payload) {
    state.notificationsInfo = payload
  },
  setUserActives(state, payload) {
    state.userActives = payload
  },
  changeSearch(state, payload) {
    const appState = state
    appState.search = payload
  },
  setTextSearch(state, payload) {
    const appState = state
    appState.search.text = payload
  },
  setConfirm(state, payload) {
    state.confirm.active = payload.active
    state.confirm.title = payload.title
    state.confirm.description = payload.description
    state.confirm.action = payload.action
  },
  setLoading(state, payload) {
    document.querySelector('html').style['overflow-y'] = 'auto'
    if (payload) document.querySelector('html').style['overflow-y'] = 'auto'
    state.loading = payload
  },
  changeDark(state, payload) {
    const appState = state
    appState.dark = payload.dark
  },
  setCars(state, payload) {
    state.cars = payload
  },
  setCommunes(state, payload) {
    state.communes = payload
  },
  setSchedules(state, payload) {
    state.schedules = payload
  },
  setHomeDetail(state, payload) {
    state.homeDetail = payload
  },
  setMini(state, payload) {
    state.mini = payload
  },
  setNewOrder(state, payload) {
    state.newOrder = payload
  },
  setSettings(state, payload) {
    state.settings = payload
  },
  updateDisenadores(state, payload) {
    state.disenadores = payload
  },
  setGeneral(state, payload) {
    state.general = payload
  },
  setRememberme(state, payload) {
    state.rememberMe = payload
  },
  setGoogle(state, a) {
    state.google = a
  },
  setColorFlowers(state, a) {
    state.colorFlowers = a
  },
  setColorPrimary(state, payload) {
    const primary = payload
    if (state.dark) {
      state.themeDark.primary = payload
    } else {
      state.themeLight.primary = payload
    }
  }
}
