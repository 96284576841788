<template>
  <v-app v-cloak :dark="dark" style="overflow: hidden">
    <template v-if="isLoggedIn && ready">
      <loading-card></loading-card>
      <v-navigation-drawer
        v-model="drawer"
        class="d-print-none"
        :width="280"
        persistent
        :mini-variant="mini"
        :clipped="clipped"
        enable-resize-watcher
        fixed
        app
      >
        <div
          class="menu-side"
          style="display: flex; flex-direction: column; height: calc(100vh - 50px); overflow: hidden"
        >
          <v-list class="pb-0 pt-1">
            <v-list-tile avatar>
              <v-list-tile-avatar v-if="user.name != undefined">
                <AvatarLetter :text="user.name" color="secondary" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ user.name }}
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ user.role.name }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <v-divider class="mb-3 mt-2"></v-divider>
          <div class="px-3 mt-0">
            <v-dialog
              ref="dialog"
              v-model="modalDate"
              :return-value.sync="date"
              persistent
              lazy
              full-width
              width="290px"
            >
              <v-text-field
                slot="activator"
                v-model="date"
                readonly
                prepend-inner-icon="date_range"
                placeholder="Informacion del día..."
                label="Informacion del día..."
                :hint="mini ? '' : 'Informacion del día...'"
                persistent-hint
                :hide-details="mini"
                :class="mini ? 'mb-2' : ''"
                solo
              ></v-text-field>
              <v-date-picker v-model="dateDatePicker" color="secondary" type="date" locale="es-es" scrollable>
                <v-btn flat color="back" @click="modalDate = false">Volver</v-btn>
                <v-spacer></v-spacer>
                <v-btn flat color="accent" @click="$refs.dialog.save(dateDatePicker)">Listo</v-btn>
              </v-date-picker>
            </v-dialog>
            <v-select
              v-model="status"
              :items="states"
              :label="mini ? '' : 'Estados'"
              prepend-inner-icon="layers"
              item-text="name"
              item-value="id"
              class="mb-4"
              solo
              hide-details
            >
              <template slot="selection" slot-scope="{ item }">
                <v-chip :color="item.color" text-color="white">{{ mini ? '' : item.name }}</v-chip>
              </template>
              <template slot="item" slot-scope="{ item }">
                <v-chip
                  v-if="item.id === 2 || item.id === 3 || item.id === 4"
                  :color="item.color"
                  text-color="white"
                  label
                  >{{ item.name }}</v-chip
                >
                <span v-else>{{ item.name }}</span>
              </template>
            </v-select>
            <div style="position: relative; display: flex; flex: 1 1 auto; z-index: 9">
              <v-text-field
                v-model="orderNumber"
                solo
                hide-details
                clearable
                :label="typeSearch ? 'Buscar clientes por nombre, email o telefono' : 'Buscar pedido'"
                color="accent"
                :prepend-inner-icon="typeSearch ? 'person' : 'local_shipping'"
                :loading="loading"
                @keyup.native="searchOrder"
                @click:clear="handleClearableSearch"
                @click:prepend-inner="typeSearch = !typeSearch"
                @click.native="$event.target.select()"
              ></v-text-field>
              <v-card
                v-if="listUser.length > 0"
                style="position: absolute; top: 53px; width: 99%; background: white"
                :dark="false"
              >
                <v-list two-line style="background: white">
                  <template v-for="(item, index) in listUser">
                    <v-list-tile :key="index" avatar @click="handleClickUser(item)">
                      <v-list-tile-avatar>
                        <AvatarLetter :text="item.name" color="secondary" />
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title class="black--text" v-html="item.name"></v-list-tile-title>
                        <v-list-tile-sub-title>
                          <span class="text--primary">{{ item.email }}</span> |
                          <span class="caption">Ultimo inicio sesion el {{ item.fecha_up }}</span>
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider
                      v-if="index + 1 !== listUser.length"
                      :key="`a${index}`"
                      style="1px solid rgba(255, 255, 255, 0.12)"
                    ></v-divider>
                  </template>
                </v-list>
              </v-card>
            </div>
          </div>

          <v-divider class="mt-4"></v-divider>
          <NavMenu />
          <div
            class="icon-buttons"
            style="bottom: 0; left: 0; position: absolute; width: 100%; border-top: 1px solid rgba(0, 0, 0, 0.12)"
          >
            <v-btn v-if="$mq !== 'xs' && $mq !== 'sm' && $mq !== 'md'" style="" icon @click="mini = !mini">
              <v-icon v-if="!mini">chevron_left</v-icon>
              <v-icon v-else>chevron_right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-navigation-drawer>
      <v-toolbar dark color="primary" class="d-print-none" app :clipped-left="clipped" :dense="$mq === 'md'">
        <v-toolbar-side-icon v-if="$mq === 'xs' || $mq === 'sm' || $mq === 'md'" @click.stop="drawer = !drawer">
        </v-toolbar-side-icon>
        <v-toolbar-title
          v-if="$mq !== 'xs'"
          :class="$mq !== 'xs' ? 'ml-0 mr-0 font-weight-regular' : 'ml-3 mr-5 font-weight-regular'"
          v-html="title"
        >
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-if="search.active"
          v-model="searchText"
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="search"
          clearable
          :label="search.placeholder"
          @focus.native="$event.target.select()"
        ></v-text-field>
        <v-spacer v-if="search.active"></v-spacer>
        <v-spacer v-if="search.active"></v-spacer>
        <v-badge color="success" left>
          <template v-slot:badge>
            <span v-html="statusFinish.length"></span>
          </template>
          <span style="cursor: pointer" @click="changeStatus(4)">Entregados</span>
        </v-badge>
        <v-badge class="ml-4" color="info" left>
          <template v-slot:badge>
            <span v-html="statusShipping.length"></span>
          </template>
          <span style="cursor: pointer" @click="changeStatus(3)">En Despacho</span>
        </v-badge>
        <v-badge class="ml-4" color="warning" left>
          <template v-slot:badge>
            <span v-html="statusProcess.length"></span>
          </template>
          <span style="cursor: pointer" @click="changeStatus(2)">En proceso</span>
        </v-badge>
        <notification-menu-info />
      </v-toolbar>
    </template>
    <router-view />
    <v-snackbar v-model="snackbar.active" :multi-line="true" :top="true" :timeout="3000">
      {{ snackbar.text }}
      <v-btn color="accent" flat @click="$store.dispatch('changeSnack', { active: false, text: '' })">
        <v-icon> close </v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="ia.active" persistent width="500">
      <v-card>
        <v-card-title class="headline warning pa-3"> Usar herramienta de IA para textos </v-card-title>
        <v-card-text class="body-1">
          <v-textarea v-model="ia.text" :label="`Texto a generar`" />
          <v-btn color="primary" class="ma-0" @click="searchIA"> Genera </v-btn>
          <v-alert :value="iaText !== ''" type="info">
            <p class="mb-0" v-html="iaText"></p>
            <v-btn color="secondary" small class="ma-0 mt-2" @click="copy(iaText)"> Copiar </v-btn>
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="text" flat @click="$store.dispatch('changeIa', { active: false, text: '' })"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAlert" width="500">
      <v-card>
        <v-card-title class="headline warning pa-3"> Alerta </v-card-title>
        <v-card-text class="body-1"> Se ha cerrado la sesion, inicio sesion en otro Dispositivo. </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="dialogAlert = false"> Lo entiendo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirm.active" width="280">
      <v-card>
        <v-toolbar class="elevation-0">
          <v-toolbar-title class="font-weight-regular title" style="white-space: inherit">
            {{ confirm.title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="confirm.description !== ''" class="body-1">
          {{ confirm.description }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" class="text--darken-2" flat @click="confirm.active = false"> Volver </v-btn>
          <v-btn color="secondary" flat @click="confirm.action"> Seguro </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import { ORDER, ORDER_NAV, GENERAL_CONFIG, GET_SEARCH_BUYER, GET_IA } from './config'
import notificationMenuInfo from './components/notifications/notificationMenuInfo.vue'
import loadingCard from './components/useful/loadingCard.vue'
import AvatarLetter from './components/general/avatarLetter.vue'
import NavMenu from './components/general/navMenu.vue'

export default {
  name: 'App',
  metaInfo: {
    title: 'Inicio',
    titleTemplate: '%s | Red Florerias Rosalinda'
  },
  components: {
    notificationMenuInfo,
    loadingCard,
    AvatarLetter,
    NavMenu
  },
  data() {
    return {
      notificationOrders: [],
      notificationWsp: [],
      orders: [],
      listUser: [],
      initApp: false,
      notSound: false,
      dialogConfirm: false,
      clipped: false,
      drawer: false,
      fixed: false,
      loading: false,
      dialogAlert: false,
      modalDate: false,
      orderNumber: null,
      dateContest: null,
      dateDatePicker: null,
      right: false,
      rightDrawer: false,
      typeSearch: false,
      mountedUserActive: null,
      ready: false,
      iaText: ''
    }
  },
  computed: {
    statusFinish() {
      return this._.filter(this.orders, ['status', 4])
    },
    statusShipping() {
      return this._.filter(this.orders, ['status', 3])
    },
    statusProcess() {
      return this._.filter(this.orders, ['status', 2])
    },
    settings() {
      return this.$store.getters.getSettings
    },
    states() {
      return this.$store.getters.getStates
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    snackbar() {
      return this.$store.getters.getSnack
    },
    ia() {
      return this.$store.getters.getIa
    },
    title() {
      return this.$store.getters.getTitle
    },
    search() {
      return this.$store.getters.getSearch
    },
    confirm() {
      return this.$store.getters.getConfirm
    },
    searchText: {
      set(text) {
        this.$store.dispatch('setTextSearch', text)
      },
      get() {
        const search = this.$store.getters.getSearch
        return search.text
      }
    },
    mini: {
      set(bool) {
        this.$store.dispatch('setMini', bool)
      },
      get() {
        return this.$store.getters.getMini
      }
    },
    date: {
      set(value) {
        const general = this.$store.getters.getGeneral
        general.date = value
        this.$store.dispatch('setGeneral', general)
      },
      get() {
        const general = this.$store.getters.getGeneral
        return general.date
      }
    },
    status: {
      set(value) {
        const general = this.$store.getters.getGeneral
        general.status = value
        this.$store.dispatch('setGeneral', general)
      },
      get() {
        const general = this.$store.getters.getGeneral
        return general.status
      }
    },
    general() {
      return this.$store.getters.getGeneral
    },
    dark() {
      return this.$store.getters.getDark
    }
  },
  watch: {
    'ia.active': function date(e) {
      if (e && this.ia.text !== '') {
        this.searchIA()
      }
    },
    'general.date': function date(e) {
      if (e && this.user.token) {
        this.searchOrdersNav()
      }
    },
    user(e) {
      if (e.token && this.general.date) {
        if (e.shopID) {
          // console.log('hi')
        }
        this.searchOrdersNav()
        this.getGeneralConfig()
      }
    }
  },
  mounted() {
    this.$store.commit('RESET_STATE')
    this.drawer = this.$mq !== 'sm' && this.$mq !== 'md'
    setTimeout(() => {
      this.$vuetify.theme = this.$store.getters.getTheme
    }, 500)
    setTimeout(() => {
      this.ready = true
    }, 600)
  },
  created() {
    this.dateDatePicker = this.date
  },
  methods: {
    async searchIA() {
      try {
        const res = await this.$http.post(GET_IA, { text: this.ia.text })
        if (res.data.choices.length > 0) {
          const response = res.data.choices[0].text
          this.iaText = response
        }
        this.$store.dispatch('changeSnack', { active: true, text: 'Se ha generado el texto' })
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    async copy(text) {
      console.log(text)
      try {
        await navigator.clipboard.writeText(text)
        this.$store.dispatch('changeSnack', { active: true, text: 'Se ha copiado' })
      } catch (err) {
        console.error('Failed to copy: ', err)
      }
    },
    changeStatus(s) {
      this.status = s
      this.$router.push({ name: 'pedidos' })
    },
    async searchOrdersNav() {
      try {
        const res = await this.$http.get(ORDER_NAV, {
          params: {
            date: this.general.date
          }
        })
        this.orders = res.data
      } catch (error) {
        // this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' });
      }
    },
    handleClickUser(item) {
      this.$router.push({ name: 'buyerDetail', params: { id: item.id } })
      this.listUser = []
    },
    handleClearableSearch() {
      if (this.typeSearch) {
        this.listUser = []
      }
    },
    handleClickSelect(e) {
      e.target.select()
    },
    // eslint-disable-next-line no-undef
    searchOrder: _.debounce(function debounce(e) {
      if (e.keyCode === 13) {
        this.loading = true
        if (!this.typeSearch) {
          this.$http.get(`${ORDER}/${this.orderNumber}`).then(res => {
            if (res.data.id) {
              this.loading = false
              const url = { name: 'orderIndex', params: { id: res.data.id } }
              const routeData = this.$router.resolve(url)
              window.open(routeData.href, '_blank')
            } else {
              this.loading = false
              this.$store.dispatch('changeSnack', {
                active: true,
                text: 'No existe el pedido ingresado'
              })
            }
          })
        }
      }
      if (e.keyCode !== 13) {
        if (this.typeSearch) {
          this.$http
            .get(GET_SEARCH_BUYER, {
              params: {
                email: this.orderNumber
              }
            })
            .then(res => {
              this.listUser = res.data
            })
        }
      }
    }, 500),
    async getGeneralConfig() {
      try {
        const res = await this.$http.get(GENERAL_CONFIG)
        this.$store.dispatch('setCommunes', res.data.communes)
        this.$store.dispatch('setCars', res.data.cars)
        this.$store.dispatch('setSchedules', res.data.schedules)
        this.$store.dispatch('updatePayments', res.data.payments)
        this.$store.dispatch('updateComments', res.data.messages)
        this.$store.dispatch('updateStates', res.data.states)
        this.$store.dispatch('updateDisenadores', res.data.disenadores)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    }
  }
}
</script>

<style>
@media print {
  .application .d-print-none {
    display: none !important;
  }
  .application .v-content {
    padding: 10px 0px 0px 10px !important;
  }
  .v-alert > div {
    color: #000;
  }
  .v-card__text {
    height: auto !important;
  }
}
.theme--light.application {
  font-weight: normal;
  font-size: 14px;
  background: #eee;
  color: #3c4858 !important;
}
.link-text {
  cursor: pointer;
}
.link-text:hover {
  opacity: 0.7;
  border-bottom: 1px #e0e0e0 solid;
}
.link-text:hover i {
  display: inline-block;
}
.link-text i {
  font-size: 11px;
  vertical-align: middle;
  border: 1px #e0e0e0 solid;
  border-radius: 50px;
  display: none;
  padding: 3px;
}
.application.theme--dark h1 {
  color: #ffffff;
}
@media screen and (max-width: 920px) and (min-width: 521px) {
  h1 {
    font-size: 30px;
  }
}
@media screen and (min-width: 921px) {
  h1 {
    font-size: 30px;
    color: #202124;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 8px;
  }
}
.sub-title {
  color: #5f6368;
  font-size: 14px;
  line-height: 1.5;
}
.application.theme--dark .v-content__wrap {
  background: #303030;
}
.no-link {
  text-decoration: none;
}
.not-scroll {
  overflow: inherit;
}
.v-list .is-active .v-list__tile {
  background: #ed4b82 !important;
  color: #ffffff;
}
.v-list .is-active .v-icon {
  color: #ffffff;
}
</style>
