<template>
  <v-list dense class="pa-0" style="overflow:auto;">
    <template v-for="item in items">
      <v-layout v-if="item.heading" :key="item.heading" row align-center>
        <v-flex xs12>
          <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
        </v-flex>
      </v-layout>
      <v-list-group
        v-else-if="item.children && checkRoute(item)"
        :key="item.text"
        v-model="item.model"
        :prepend-icon="item.icon ? item.icon : ''"
        :append-icon="item.model ? 'arrow_drop_up' : 'arrow_drop_down'"
      >
        <v-list-tile slot="activator">
          <v-list-tile-content>
            <v-list-tile-title>{{ item.text }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-for="(child, i) in item.children"
          v-if="checkRoute(child)"
          :key="i"
          exact
          :to="handleClickList(child.route, child.query)"
        >
          <v-list-tile-action v-if="child.icon">
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ child.text }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list-group>
      <v-divider v-else-if="item.divider" :key="`divider-${item.divider}`"></v-divider>
      <v-list-tile
        v-else-if="checkRoute(item)"
        :key="item.text"
        :class="item.className"
        :to="handleClickList(item.route)"
      >
        <v-list-tile-action :class="item.classText">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content :class="item.classText">
          <v-list-tile-title>{{ item.text }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
    <v-divider></v-divider>
    <v-list-tile @click="logoutAction">
      <v-list-tile-action>
        <v-icon>exit_to_app</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Cerrar Sesion</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>

<script>
import * as PusherPushNotifications from '@pusher/push-notifications-web'
import { auth } from '../../fireStore'

export default {
  name: 'NavMenu',
  data() {
    return {
      items: []
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getMenu()
  },
  methods: {
    async getMenu() {
      const items = await this.$http.get('/json/menu.json')
      let menu = []
      if (this.user.role.id === 6) {
        menu = items.data.asociado
      } else if (this.user.role.id === 7) {
        menu = items.data.taller
      } else if (this.user.role.id !== 6) {
        menu = items.data.otro
      }
      this.items = menu
    },
    logoutAction() {
      const beamsClient = new PusherPushNotifications.Client({
        instanceId: '1c114c39-04a6-4ecd-86c1-79e3788620de'
      })

      beamsClient
        .start()
        .then(() => beamsClient.removeDeviceInterest(`orders-${this.user.shopID}`))
        .catch(console.error)

      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Cerrar sesion?',
        description: '',
        action: this.logOut
      })
    },
    logOut() {
      this.$store.dispatch('logout')
      this.$store.dispatch('setConfirm', { active: false })
      auth.signOut()
    },
    checkRoute(item) {
      const { user } = this
      if (item.text === 'Insumos/Control de stock' && !user.store.stock) {
        return false
      }
      if (item.text === 'Cuenta del banco' && !user.store.bank_id) {
        return false
      }
      if (item.text === 'Ofertas del día') {
        return false
      }
      if (item.text === 'Estadisticas' && !user.shop.admin) {
        return false
      }
      if (item.route === 'reqInsumos' && user.role.id !== 1 && user.role.id !== 6) {
        return false
      }
      if (
        (item.route === 'Checkout' ||
          item.route === 'ordersMap' ||
          item.route === 'storeHome' ||
          item.route === 'cashDesk' ||
          item.route === 'cashDeskBank') &&
        user.role.id === 4
      ) {
        return false
      }
      if (item.text === 'Informes' && (user.role.id !== 1 || !user.shop.admin)) {
        return false
      }

      return true
    },
    handleClickList(name, query) {
      if (name === 'logout') {
        return {}
      }
      return { name, query }
    }
  }
}
</script>
