export default {
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../components/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        title: 'Inicio'
      }
    },
    {
      path: '/inicio',
      name: 'index',
      component: () => import('../components/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        title: 'Resumen del dia'
      }
    },
    {
      path: '/portal-de-la-muerte',
      name: 'portalDeLaMuerte',
      component: () => import('../components/portal/index.vue'),
      meta: {
        requiresAuth: true,
        title: 'Portal de la muerte'
      }
    },
    {
      path: '/pedidos',
      name: 'pedidos',
      component: () => import('../components/Home.vue'),
      meta: {
        requiresAuth: true,
        title: 'Pedidos'
      }
    },
    {
      path: '/pedidos/:diseno',
      name: 'pedidosDiseno',
      props: true,
      component: () => import('../components/Home.vue'),
      meta: {
        requiresAuth: true,
        title: 'Pedidos - Vista elaboracion'
      }
    },
    {
      path: '/historial-notificaciones',
      name: 'historyNotifications',
      component: () => import('../components/notifications/index.vue'),
      meta: {
        requiresAuth: true,
        title: 'Historial de notificaciones'
      }
    },
    {
      path: '/iniciar-sesion',
      name: 'login',
      component: () => import('../components/Login.vue'),
      meta: {
        requiresAuth: false,
        title: 'Iniciar sesion'
      }
    },
    {
      path: '/mapa-de-pedidos',
      name: 'ordersMap',
      component: () => import('../components/OrdersMap.vue'),
      meta: {
        requiresAuth: true,
        title: 'Mapa de pedidos'
      }
    },
    {
      path: '/ruta-repartidor/:dealerID',
      name: 'ordersMapDealer',
      component: () => import('../components/OrdersMap.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Ruta del repartidor'
      }
    },
    {
      path: '/mapa-de-pedidos/:day/:states',
      name: 'ordersMapFilter',
      component: () => import('../components/OrdersMap.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Pedidos en el mapa'
      }
    },
    {
      path: '/insumos',
      name: 'Supplies',
      component: () => import('../components/inventory/supplies.vue'),
      meta: {
        requiresAuth: true,
        title: 'Insumos'
      }
    },
    {
      path: '/qr-insumo',
      name: 'QRStock',
      component: () => import('../components/inventory/qrSupplie.vue'),
      meta: {
        requiresAuth: true,
        title: 'Ver insumo QR'
      }
    },
    {
      path: '/qr-insumo/:code',
      name: 'QRStockID',
      component: () => import('../components/inventory/qrSupplyId.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Insumo Stock'
      }
    },
    {
      path: '/caja-chica',
      name: 'cashDesk',
      component: () => import('../components/CashDesk.vue'),
      meta: {
        requiresAuth: true,
        title: 'Cajas'
      }
    },
    {
      path: '/caja-del-banco',
      name: 'cashDeskBank',
      component: () => import('../components/CashDeskBank.vue'),
      meta: {
        requiresAuth: true,
        title: 'Banco'
      }
    },
    {
      path: '/tomar-inventario',
      name: 'TellInvetory',
      component: () => import('../components/inventory/tellInvetory.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Tomar inventario'
      }
    },
    {
      path: '/inventarios',
      name: 'Inventory',
      component: () => import('../components/inventory/index.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Inventario'
      }
    },
    {
      path: '/inventarios/:id',
      name: 'InventoryDetail',
      component: () => import('../components/inventory/inventoryDetail.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Inventario'
      }
    },
    {
      path: '/whatsapp/',
      component: () => import('../components/whatsapp/index.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Whatsapp'
      },
      children: [
        {
          path: '',
          name: 'whatsappHome',
          component: () => import('../components/whatsapp/chat.vue')
        },
        {
          path: ':number',
          name: 'whatsappChat',
          component: () => import('../components/whatsapp/chat.vue')
        }
      ]
    },
    {
      path: '/imprimir-pedido/:id',
      name: 'orderPrint',
      component: () => import('../components/order/print.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Imprimir'
      }
    },
    {
      path: '/imprimir-pedido-pos/:id',
      name: 'orderPrint',
      component: () => import('../components/order/printPos.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Imprimir'
      }
    },
    {
      path: '/pedido/:id/',
      component: () => import('../components/order/index.vue'),
      props: true,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'orderIndex',
          component: () => import('../components/order/detail.vue')
        },
        {
          path: 'detalle',
          name: 'orderHome',
          component: () => import('../components/order/detail.vue')
        },
        {
          path: 'mensaje',
          name: 'orderMessage',
          component: () => import('../components/order/message.vue')
        },
        {
          path: 'productos',
          name: 'orderProducts',
          component: () => import('../components/order/products.vue')
        },
        {
          path: 'locacion',
          name: 'orderLocation',
          component: () => import('../components/order/location.vue')
        },
        {
          path: 'disenador',
          name: 'orderDesign',
          component: () => import('../components/order/designer.vue')
        },
        {
          path: 'historial',
          name: 'orderHistory',
          component: () => import('../components/order/history.vue')
        },
        {
          path: 'whatsapp-order',
          name: 'orderWhatsapp',
          component: () => import('../components/order/whatsapp.vue')
        },
        {
          path: 'costos-adicionales',
          name: 'orderExtraCost',
          component: () => import('../components/order/extraCost.vue')
        }
      ]
    },
    {
      path: '/mi-tienda/',
      component: () => import('../components/myStore/index.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Mi tienda'
      },
      children: [
        {
          path: '',
          name: 'storeHome',
          component: () => import('../components/myStore/home.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Inicio'
          }
        },
        {
          path: 'insumos',
          name: 'storeInsumos',
          component: () => import('../components/myStore/insumos.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Solicitar insumos'
          }
        },
        {
          path: 'general',
          name: 'storeSettings',
          component: () => import('../components/myStore/general.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - General'
          }
        },
        {
          path: 'comunas',
          name: 'storeStates',
          component: () => import('../components/myStore/states.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Comunas'
          }
        },
        {
          path: 'disenadores',
          name: 'storeFlowers',
          component: () => import('../components/myStore/flowers.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Diseñadores'
          }
        },
        {
          path: 'repartidores',
          name: 'storeDealers',
          component: () => import('../components/myStore/dealers.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Repartidores'
          }
        },
        {
          path: 'rutas',
          name: 'storeDealersRoutes',
          component: () => import('../components/myStore/dealerRoutes.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Rutas'
          }
        },
        {
          path: 'rutas/:id',
          name: 'storeDealersRoutesDetail',
          component: () => import('../components/myStore/dealerRoutesDetail.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Rutas'
          }
        },
        {
          path: 'alta-demanda',
          name: 'storeDailySale',
          component: () => import('../components/myStore/dailySale.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Alta Demanda'
          }
        },
        {
          path: 'horarios-de-entrega',
          name: 'storeSchedule',
          component: () => import('../components/myStore/schedule.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Horarios de entrega'
          }
        },
        {
          path: 'configuracion-zona',
          name: 'storeZone',
          component: () => import('../components/myStore/zone.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Configuracion'
          }
        },
        {
          path: 'bloqueo-de-horarios',
          name: 'storeBlockSchedule',
          component: () => import('../components/myStore/blockSchedule.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Horarios de entrega'
          }
        },
        {
          path: 'estadisticas',
          name: 'storeStatistics',
          component: () => import('../components/myStore/statistics/index.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Estadisticas'
          }
        },
        {
          path: 'estadisticas/:id',
          name: 'storeStatisticsID',
          props: true,
          component: () => import('../components/myStore/statistics/_id.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Estadisticas TYPE'
          }
        },
        {
          path: 'liquidacion-de-pago-tiendas',
          name: 'storePayStoreAdmin',
          component: () => import('../components/myStore/storePayStoreAdmin.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Liquidacion de pago'
          }
        },
        {
          path: 'liquidacion-de-pago',
          name: 'storePayStore',
          component: () => import('../components/myStore/payStore.vue'),
          meta: {
            requiresAuth: true,
            title: 'Mi tienda - Liquidacion de pago'
          }
        }
      ]
    },
    {
      path: '/cliente/:id/',
      component: () => import('../components/buyer/index.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Cliente'
      },
      children: [
        {
          path: '',
          name: 'buyerDetail',
          component: () => import('../components/buyer/detail.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            title: 'Cliente - Datos personales'
          }
        },
        {
          path: 'facturacion',
          name: 'buyerSii',
          component: () => import('../components/buyer/sii.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            title: 'Cliente - Facturacion'
          }
        },
        {
          path: 'pedidos',
          name: 'buyerOrders',
          component: () => import('../components/buyer/orders.vue'),
          meta: {
            requiresAuth: true,
            title: 'Cliente - Pedidos'
          }
        },
        {
          path: 'cupones',
          name: 'buyerCoupons',
          component: () => import('../components/buyer/coupons.vue'),
          meta: {
            requiresAuth: true,
            title: 'Cliente - Cupones'
          }
        },
        {
          path: 'encuestas',
          name: 'buyerPolls',
          component: () => import('../components/buyer/polls.vue'),
          meta: {
            requiresAuth: true,
            title: 'Cliente - Encuestas'
          }
        },
        {
          path: 'reclamos',
          name: 'buyerClaims',
          component: () => import('../components/buyer/claims.vue'),
          meta: {
            requiresAuth: true,
            title: 'Cliente - Reclamos'
          }
        },
        {
          path: 'recordatorio-de-fechas-importantes',
          name: 'buyerDates',
          component: () => import('../components/buyer/dates.vue'),
          meta: {
            requiresAuth: true,
            title: 'Cliente - Recordatorio de fechas importantes'
          }
        },
        {
          path: 'suscripciones',
          name: 'buyerSuscriptions',
          component: () => import('../components/buyer/suscriptions.vue'),
          meta: {
            requiresAuth: true,
            title: 'Cliente - Suscripciones'
          }
        },
        {
          path: 'direcciones-de-entrega',
          name: 'buyerAddress',
          component: () => import('../components/buyer/address.vue'),
          meta: {
            requiresAuth: true,
            title: 'Cliente - Direcciones de entrega'
          }
        },
        {
          path: 'chat-whatsapp',
          name: 'buyerWhatsApp',
          component: () => import('../components/buyer/whatsapp.vue'),
          meta: {
            requiresAuth: true,
            title: 'Cliente - Chat WhatsApp'
          }
        }
      ]
    },
    {
      path: '/configuracion/',
      component: () => import('../components/settings/index.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'General'
      },
      children: [
        {
          path: '',
          name: 'settings',
          component: () => import('../components/settings/general.vue'),
          meta: {
            requiresAuth: true,
            title: 'General'
          }
        },
        {
          path: 'general',
          name: 'settingsGeneral',
          component: () => import('../components/settings/general.vue'),
          meta: {
            requiresAuth: true,
            title: 'General'
          }
        },
        {
          path: 'archivos-publicos',
          name: 'settingsFiles',
          component: () => import('../components/settings/files.vue')
        },
        {
          path: 'global',
          name: 'settingsGlobal',
          component: () => import('../components/settings/global.vue')
        },
        {
          path: 'mensajes-predefinidos',
          name: 'settingsMsjWsp',
          component: () => import('../components/settings/settingsMsjWsp.vue')
        },
        {
          path: 'envio-masivo',
          name: 'settingsEnvioMasivo',
          component: () => import('../components/settings/envio-masivo/index.vue')
        },
        {
          path: 'envio-masivo/nuevo',
          name: 'settingsEnvioMasivoNuevo',
          component: () => import('../components/settings/envio-masivo/nuevo.vue')
        },
        {
          path: 'envio-masivo/:id',
          props: true,
          name: 'settingsEnvioMasivoId',
          component: () => import('../components/settings/envio-masivo/_id.vue')
        },
        {
          path: 'wsp-especial',
          name: 'settingsWspSpecial',
          component: () => import('../components/settings/wspSpecial.vue')
        },
        {
          path: 'blog/entradas',
          name: 'settingsBlogPosts',
          component: () => import('../components/settings/blog/all.vue')
        },
        {
          path: 'blog/categorias',
          name: 'settingsBlogCategory',
          component: () => import('../components/settings/blog/categories.vue')
        },
        {
          path: 'blog/categorias/:id/',
          props: true,
          name: 'settingsBlogCategoryId',
          component: () => import('../components/settings/blog/categoriesId.vue')
        },
        {
          path: 'blog/entradas/:id/',
          props: true,
          name: 'settingsBlogPost',
          component: () => import('../components/settings/blog/post.vue')
        },
        {
          path: 'categorias',
          name: 'settingsCategories',
          component: () => import('../components/settings/category.vue')
        },
        {
          path: 'categorias/:id',
          props: true,
          name: 'settingsCategoriesEdit',
          component: () => import('../components/settings/categoryID.vue')
        },
        {
          path: 'direcciones',
          name: 'settingsAddress',
          component: () => import('../components/settings/address.vue')
        },
        {
          path: 'notifiaciones-push',
          name: 'settingsPush',
          component: () => import('../components/settings/push.vue')
        },
        {
          path: 'direcciones/:id',
          props: true,
          name: 'settingsAddressEdit',
          component: () => import('../components/settings/addressID.vue')
        },
        {
          path: 'paginas-web-mobile',
          name: 'settingsViews',
          component: () => import('../components/settings/view.vue')
        },
        {
          path: 'links-de-interes',
          name: 'settingsLinks',
          component: () => import('../components/settings/links.vue')
        },
        {
          path: 'configuracion-web',
          name: 'settingsConfigWeb',
          component: () => import('../components/settings/configWeb.vue')
        },
        {
          path: 'cms',
          name: 'settingsCMS',
          component: () => import('../components/settings/cms.vue')
        },
        {
          path: 'cms/:id',
          props: true,
          name: 'settingsCMSEdit',
          component: () => import('../components/settings/cmsID.vue')
        },
        {
          path: 'preguntas-frecuentes',
          name: 'settingsFaq',
          component: () => import('../components/settings/faq.vue')
        },
        {
          path: 'preguntas-frecuentes/:id',
          props: true,
          name: 'settingsFaqEdit',
          component: () => import('../components/settings/faqID.vue')
        },
        {
          path: 'encuestas',
          name: 'statisticsPolls',
          component: () => import('../components/statistics/polls.vue'),
          meta: {
            requiresAuth: true,
            title: 'Informes - Encuestas'
          }
        },
        {
          path: 'envios-fechas-importantes',
          name: 'statisticsDateImportant',
          component: () => import('../components/statistics/dateImportant.vue'),
          meta: {
            requiresAuth: true,
            title: 'Informes - Fechas importantes'
          }
        },
        {
          path: 'consolidar-pedidos-fechas-importantes',
          name: 'statisticsOrderDate',
          component: () => import('../components/statistics/orderConsolidates.vue'),
          meta: {
            requiresAuth: true,
            title: 'Informes - Pedidos consolidados'
          }
        },
        {
          path: 'encuestas-enviadas',
          name: 'statisticsPollSendStatics',
          component: () => import('../components/statistics/pollSendStatics.vue'),
          meta: {
            requiresAuth: true,
            title: 'Informes - Encuestas enviadas'
          }
        },
        {
          path: 'cupones-de-descuento',
          name: 'discountCoupons',
          component: () => import('../components/statistics/discountCoupons.vue'),
          meta: {
            requiresAuth: true,
            title: 'Informes - Cupones de descuento'
          }
        }
      ]
    },
    {
      path: '/estadisticas/',
      component: () => import('../components/statistics/index.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Estadisticas'
      },
      children: [
        {
          path: '',
          name: 'statisticsDashboard',
          component: () => import('../components/statistics/dashboard.vue'),
          meta: {
            requiresAuth: true,
            title: 'Informes - Inicio'
          }
        }
      ]
    },
    {
      path: '/cupones-de-descuento/:id',
      name: 'editDiscountCoupons',
      component: () => import('../components/statistics/editDiscountCoupons.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Cupon de descuento'
      }
    },
    {
      path: '/productos/',
      component: () => import('../components/products/all.vue'),
      name: 'products',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Productos'
      }
    },
    {
      path: '/etiquetas',
      component: () => import('../components/tags/index.vue'),
      name: 'tags',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Etiquetas'
      }
    },
    {
      path: '/etiquetas/:id',
      component: () => import('../components/tags/id.vue'),
      name: 'tagsId',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Etiquetas'
      }
    },
    {
      path: '/productos-colores',
      component: () => import('../components/products/colors.vue'),
      name: 'productsColors',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Productos de colores'
      }
    },
    {
      path: '/caracteristicas',
      component: () => import('../components/products/features/index.vue'),
      name: 'productsFeatures',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Caracteristicas'
      }
    },
    {
      path: '/caracteristicas/:id',
      component: () => import('../components/products/features/id.vue'),
      name: 'productsFeaturesValues',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Caracteristicas'
      }
    },
    {
      path: '/productos-colores/:id',
      component: () => import('../components/products/grupoAtributos/id.vue'),
      name: 'productsColorsEdit',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Productos de colores'
      }
    },
    {
      path: '/atributos/:id',
      component: () => import('../components/products/atributos.vue'),
      name: 'productAtributos',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Productos de colores'
      }
    },
    {
      path: '/atributos-productos/:id',
      component: () => import('../components/products/atributos-productos.vue'),
      name: 'productAtributosList',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Productos de colores'
      }
    },
    {
      path: '/vista-precios-productos/',
      component: () => import('../components/products/viewPrices.vue'),
      name: 'productsPrices',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Productos - por precio'
      }
    },
    {
      path: '/vista-precios-productos/?:params',
      component: () => import('../components/products/viewPrices.vue'),
      name: 'productsPricesParams',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Productos'
      }
    },
    {
      path: '/ofertas-del-dia/',
      component: () => import('../components/products/saleDay.vue'),
      name: 'productsSaleDay',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Ofertas del día'
      }
    },
    {
      path: '/suscripciones',
      component: () => import('../components/products/suscription.vue'),
      name: 'productsSuscription',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Suscripciones'
      }
    },
    {
      path: '/venta-de-cajas-por-color',
      component: () => import('../components/products/cajas/index.vue'),
      name: 'productsCaja',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Venta de cajas por color'
      }
    },
    {
      path: '/venta-de-cajas-por-color/:id',
      component: () => import('../components/products/cajas/_id.vue'),
      name: 'productsCajaId',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Venta de cajas por color'
      }
    },
    {
      path: '/cerras-pedido/',
      component: () => import('../components/order/closeOrder.vue'),
      name: 'closeOrder',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Cerrar pedido'
      }
    },
    {
      path: '/ofertas-del-dia/nuevo',
      component: () => import('../components/products/saleDayNew.vue'),
      name: 'productsSaleDayNew',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Nuevo producto del día'
      }
    },
    {
      path: '/ofertas-del-dia/:id',
      component: () => import('../components/products/saleDayNew.vue'),
      name: 'productsSaleDayEdit',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Nuevo producto del día'
      }
    },
    {
      path: '/productos/:id/',
      component: () => import('../components/products/index.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'General'
      },
      children: [
        {
          path: '',
          name: 'product',
          component: () => import('../components/products/price.vue')
        },
        {
          path: 'seo',
          name: 'productSeo',
          component: () => import('../components/products/seo.vue')
        },
        {
          path: 'categorias',
          name: 'productCategory',
          component: () => import('../components/products/categories.vue')
        },
        {
          path: 'caracteristicas',
          name: 'productFeatures',
          component: () => import('../components/products/feature.vue')
        },
        {
          path: 'imagenes',
          name: 'productImages',
          component: () => import('../components/products/image.vue')
        },
        {
          path: 'estado',
          name: 'productStatus',
          component: () => import('../components/products/status.vue')
        },
        {
          path: 'precio',
          name: 'productPrice',
          component: () => import('../components/products/price.vue')
        },
        {
          path: 'atributos',
          name: 'productAttributes',
          component: () => import('../components/products/attributes.vue')
        },
        {
          path: 'colores',
          name: 'productColors',
          component: () => import('../components/products/color_product.vue')
        },
        {
          path: 'fotos-de-entregas',
          component: () => import('../components/products/delivery.vue'),
          name: 'productsDelivery'
        },
        {
          path: 'receta',
          component: () => import('../components/products/receta.vue'),
          name: 'productReceta'
        },
        {
          path: 'estadisticas',
          component: () => import('../components/products/statistics.vue'),
          name: 'productStatistics'
        },
        {
          path: 'google-merchant-center',
          component: () => import('../components/products/google.vue'),
          name: 'productMerchant'
        }
      ]
    },
    {
      path: '/anadir-productos-a-tu-tienda',
      component: () => import('../components/products/addStore.vue'),
      name: 'productAddStore',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Añadir productos'
      }
    },
    {
      path: '/imprimir-pedidos',
      component: () => import('../components/PrintOrders.vue'),
      name: 'printOrders',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Imprimir pedidos'
      }
    },
    {
      path: '/pedidos-sin-boleta',
      component: () => import('../components/DocumentOrders.vue'),
      name: 'DocumentOrders',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Pedidos sin boleta o factura'
      }
    },
    {
      path: '/pedidos-sin-ubicacion',
      component: () => import('../components/DocumentGeolocalization.vue'),
      name: 'DocumentGeolocalization',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Pedidos sin ubicacion'
      }
    },
    {
      path: '/cargar-comentarios-google',
      component: () => import('../components/CommentGoogle.vue'),
      name: 'CommentsGoogle',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Cargar comentarios Goole'
      }
    },
    {
      path: '/reporte-de-problemas',
      component: () => import('../components/Help.vue'),
      name: 'help',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Reporte de problemas'
      }
    },
    {
      path: '/nuevo-pedido/',
      component: () => import('../components/checkout/index.vue'),
      name: 'Checkout',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Nuevo Pedido'
      }
    },
    {
      path: '/nuevo-pedido/:userID',
      component: () => import('../components/checkout/index.vue'),
      name: 'CheckoutUserID',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Nuevo Pedido'
      }
    },
    {
      path: '/agregar-producto',
      component: () => import('../components/products/add.vue'),
      name: 'productAdd',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Añadir producto'
      }
    },
    {
      path: '/solicitud-insumos',
      component: () => import('../components/ReqInsumos.vue'),
      name: 'reqInsumos',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Solicitudes de insumos'
      }
    },
    {
      path: '/inicio-asociado',
      component: () => import('../components/home/asociado.vue'),
      name: 'inicioAsociado',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Mesón del florista'
      }
    },
    {
      path: '/taller',
      component: () => import('../components/home/taller.vue'),
      name: 'taller',
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Mesón del florista'
      }
    },
    {
      path: '*',
      name: 'Page404',
      component: () => import('../components/Page404.vue')
    }
  ]
}
